<template>
    <div>
        <AddProject />
    </div>
</template>

<script>
import AddProject from './components/AddProject.vue'
export default {
    components: {
        AddProject
    }
}
</script>